<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="formattedEncoderActions"
        :default-value="properties.action"
        step-label="encoder"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>

    <v-col cols="12" class="pt-1 pb-2">
      <encoder-step-converts
        :key="`${step.id}-converts`"
        step-type="ENCODER"
        type="converts"
        :show-encodings="properties.action === 'CHANGE_ENCODING'"
        :can-edit="canEdit"
        :data="properties.converts"
        @dataChanged="handleChange('converts', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { ActionSelect } from './components'
import EncoderStepConverts from '../EncoderStepConverts'
import StepMixin from './StepMixin'

import { castValueForStep } from '@/utils/helpers'
import { definitions } from '@/utils/definitions'

export default {
  name: 'EncoderStep',
  components: {
    ActionSelect,
    EncoderStepConverts
  },
  mixins: [StepMixin],
  props: {
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      encoderActions: definitions.EncoderStepProperties.properties.action.enum.sort()
    }
  },
  computed: {
    formattedEncoderActions() {
      return this.encoderActions.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  created() {
    if (!this.properties.action) this.properties.action = 'ENCODE_HTML_SPECIAL_CHARS'
    if (!this.properties.converts) {
      this.properties.converts = []
    }
  },
  methods: {
    handleChange(field, value) {
      this.$set(this.properties, field, castValueForStep(
        field,
        value,
        {
          shouldBeString: ['sourceObject'] // Specify sourceObject for casting
        }
      ))
    }
  }
}
</script>
